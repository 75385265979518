import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Img from "gatsby-image";
import styled from "styled-components";

const useGallery = () => {
    const data = useStaticQuery(graphql`
      query ImageGalleryQuery {
      allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    `);
  
    return data.allFile.nodes.map(node => ({
      ...node.childImageSharp, // Note that we're spreading the childImageSharp object here
      id: node.id,
    }));
  };

  const Gallery = () => {
    const images = useGallery()

    return (
        <>
        <div id="galleryWrapper">          
            <div className='gallery' id='image-gallery'>
                {images.map(({ id, fluid }) => (
                    <Img key={id} fluid={fluid} />
                ))}
            </div>           
        </div>
        </>
    )
}


export default Gallery;