import React from 'react';
import Logo from '../images/Erica-Selfie.jpg'
import styled from "styled-components";
import Image from './image';
import Socials from "./socials";



const profile = ({ contacts }) => {
    return (
        <div id="profileWrapper">
            <div id="profileSection">
                <div id="imageWrapper">                    
                    <Image />
                </div>
                
                <div id="bioWrapper">
                    <h4>Meet Erica, owner and founder of Pop and Bliss! </h4>
                    <p>
                        Erica started off creating balloon pieces for family and soon realized that she enjoys creating garlands for others. 
                    </p>
                    <p>
                        With the encouragement of her husband and loved ones she moved forward with “Pop
                        and Bliss”. She hopes to continue building and growing her work by providing balloon garlands to make your your next event “pop”! 
                    </p>

                    <Socials />
                                        
                </div>
            </div>
        </div>
    )};



export default profile;
