import React from 'react';
import styled from "styled-components";

//  TO DO: Get this importing more efficiently, getting weird errors when I try to
//  import them all at once for some reason.

import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";


const socials = () => {

        return (
            <div id="socialsWrapper">
                <a class="socialsLink" href="https://www.instagram.com/popandbliss/" target="_blank">
                    <span title="Instagram">
                        <FaInstagram size={26} style={{color: "fuchsia"}}/>
                    </span>
                </a>
                <a class="socialsLink" href="https://www.facebook.com/PopandBliss" target="_blank">
                    <span title="Facebook">
                        <FaFacebook size={26} style={{color: "blue"}}/>
                    </span>
                </a>   
                <a class="socialsLink" href="mailto:Popandblisschi@gmail.com">
                    <span title="Email">
                        <FaEnvelope size={26}  />
                    </span>    
                </a>             
            </div>
        )};


export default socials;