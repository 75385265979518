import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Profile from "../components/profile";
import Gallery from "../components/gallery";
import Contact from "../components/contact";
import BackToTop from "../components/BackToTop";



const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Profile />
    <Gallery /> 
    <BackToTop/>
    <Contact/>
  </Layout>
)

export default IndexPage
