import * as React from "react"
import styled from "styled-components";



const contactform = () => (
  <>
    <div id="contactWrapper">
      <div id="contactDescription">
        <h3>Contact Pop And Bliss!</h3>
        <p>Looking for a little something extra for your party or event? <br />
          Let Pop And Bliss help your imagination come to life!
        </p>
      </div>
      <form id="contactSection" netlify name="Contact Form" method="POST" data-netlify="true" netlify-honeypot="bot-check" action="/ThankYou">
        <div>            
          <p><input type="hidden" name="form-name" value="Contact Form" /></p>
          <p><label for="name">Your Name: </label><br /><input type="text" id="name" name="name" required /></p>
          <p><label for="email">Email: </label><br /><input type="text" id="email" name="email" required /></p>
          <p>
            <label for="options">I'm looking for:</label><br />
            <select name="options" id="options" required defaultValue="">
              <option>-- Select --</option>
              <option value="Garland">Garland</option>
              <option value="Backdrop">Backdrop</option>
              <option value="Accent Pieces">Accent Pieces</option>
              <option value="Other">Other (Pleace specify in message)</option>
            </select>
          </p>
          <p><label for="message">Message: </label><br /><textarea type="text" id="message" name="message" required rows="5" cols="40" /></p>
          <p>
            <button type="submit">Submit</button>
          </p>
        </div>  
      </form>
    </div>
  </>
)

export default contactform;